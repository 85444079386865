import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from 'react-router-dom';
import MuiDialog from './MuiDialog';
import { useUser } from '../contexts/UserContext';

function Header() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null,
  );
  const navigate = useNavigate();
  const { user, setUser } = useUser(); // Access setUser from the context
  const [openDialog, setOpenDialog] = React.useState(false);
  const [anchorElSpecificNote, setAnchorElSpecificNote] =
    React.useState<null | HTMLElement>(null);
  const handleOpenSpecificNote = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElSpecificNote(event.currentTarget);
  };
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openSpecificNote = Boolean(anchorElSpecificNote);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const handleCloseSpecificNote = () => {
    setAnchorElSpecificNote(null);
  };
  const handleNormalOrder = () => {
    handleClose();
    navigate('/admin/create');
  };
  const handlePreOrder = () => {
    handleClose();
    navigate('/admin/createPre');
  };
  const handleSpecificOrder = () => {
    handleClose();
    navigate('/admin/createSpecific');
  };
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {(user.role === 'admin' || user.role === 'mod') && (
                <>
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">Admin</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate('/admin/create');
                    }}
                  >
                    <Typography textAlign="center">
                      Create Normal Form
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate('/admin/createpre');
                    }}
                  >
                    <Typography textAlign="center">
                      Create PreOrder Form
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate('/admin/createSpecific');
                    }}
                  >
                    <Typography textAlign="center">
                      Create SpecificOrder Form
                    </Typography>
                  </MenuItem>

                  {user.role === 'admin' && (
                    <>
                      <MenuItem onClick={() => setOpenDialog(true)}>
                        <Typography textAlign="center">
                          Specific Note
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        onClick={() => {
                          handleCloseNavMenu();
                          navigate('/admin/dateNotes');
                        }}
                      >
                        <Typography textAlign="center">Date Notes</Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseNavMenu();
                          navigate('/admin/usedmaterials');
                        }}
                      >
                        <Typography textAlign="center">
                          Used Materials
                        </Typography>
                      </MenuItem>
                    </>
                  )}
                </>
              )}
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu();
                  navigate('/user');
                }}
              >
                <Typography textAlign="center">Agenda</Typography>
              </MenuItem>

              <MenuItem onClick={handleCloseNavMenu}>
                <Typography
                  textAlign="center"
                  onClick={() => {
                    setUser({ role: null });
                    sessionStorage.clear();
                    navigate('/');
                  }}
                >
                  Logout
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            LOGO
          </Typography>
          {(user.role === 'admin' || user.role === 'mod') && (
            <>
              {user.role === 'admin' && (
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                  <Button
                    onClick={() => {
                      handleCloseNavMenu();
                      navigate('/admin');
                    }}
                    sx={{ my: 2, color: 'white', display: 'block' }}
                  >
                    Admin
                  </Button>
                </Box>
              )}
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                <Button
                  id="basic-button"
                  aria-controls={open ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                  sx={{ color: 'white' }}
                >
                  Create Form
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem onClick={handlePreOrder}>Pre Order</MenuItem>
                  <MenuItem onClick={handleNormalOrder}>Normal Order</MenuItem>
                  <MenuItem onClick={handleSpecificOrder}>
                    Specific Order
                  </MenuItem>
                </Menu>
              </Box>
              {user.role === 'admin' && (
                <>
                  <Box
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                  >
                    <Button
                      id="basic-button"
                      aria-controls={
                        openSpecificNote ? 'basic-menu' : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openSpecificNote ? 'true' : undefined}
                      onClick={handleOpenSpecificNote}
                      sx={{ color: 'white' }}
                    >
                      Specific Note
                    </Button>
                    <Menu
                      id="basic-menu"
                      anchorEl={anchorElSpecificNote}
                      open={openSpecificNote}
                      onClose={handleCloseSpecificNote}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                      }}
                    >
                      <MenuItem
                        onClick={() => {
                          setOpenDialog(true);
                        }}
                      >
                        Add Note
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleCloseSpecificNote();
                          navigate('/admin/datenotes');
                        }}
                      >
                        Delete Note
                      </MenuItem>
                    </Menu>
                  </Box>
                  <Box
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}
                  >
                    <Button
                      onClick={() => {
                        handleCloseNavMenu();
                        navigate('/admin/usedmaterials');
                      }}
                      sx={{ my: 2, color: 'white', display: 'block' }}
                    >
                      Used Materials
                    </Button>
                  </Box>
                </>
              )}
            </>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Button
              onClick={() => {
                handleCloseNavMenu();
                navigate('/user');
              }}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Agenda
            </Button>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex', ml: 'auto' } }}>
            <Button
              onClick={() => {
                handleCloseNavMenu();
                setUser({ role: null });
                sessionStorage.clear();
                navigate('/');
              }}
              sx={{ my: 2, color: 'white', display: 'block' }}
            >
              Logout
            </Button>
          </Box>
          <MuiDialog openDialog={openDialog} setOpenDialog={setOpenDialog} />
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default Header;
