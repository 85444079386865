import { Box, Stack } from '@mui/material';
import Header from '../components/Header';
import { Outlet, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { useEffect } from 'react';

export const Admin = () => {
  const { setUser } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    const storedRole = sessionStorage.getItem('role');
    if (storedRole === null) {
      navigate('/user');
    } else {
      setUser({ role: storedRole });
      (storedRole === 'user' || storedRole === 'administratie') &&
        navigate('/user');
    }
  }, [navigate, setUser]);

  return (
    <Box sx={{ backgroundColor: '#f9f9f9' }} minHeight="100vh">
      <Header />
      <Stack direction="row" alignItems="center" justifyContent="flex-start">
        <Outlet />
      </Stack>
    </Box>
  );
};
