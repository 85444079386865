import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Grid,
  Snackbar,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import apiEndpoint from '../api/apiEndpoints';

export const SpecificDetail = () => {
  const small = useMediaQuery('(min-width:1280px)');
  const [userData, setUserData] = useState<any>();
  const [userRole, setUserRole] = useState<string>();
  const [notification, setNotification] = useState<{
    isOpen: boolean;
    message: string;
  }>({ isOpen: false, message: '' });
  const [xClicked, setxClicked] = useState(false);
  const [isFinanced, setIsFinanced] = useState(false);
  const [isFinished, setIsFinished] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const getRecords = useCallback(() => {
    fetch(`${apiEndpoint}specific/${id}`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setUserData(data); // Update the state with the fetched data
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: 'Server Error' });
      });
  }, [id]);
  useEffect(() => {
    getRecords();
  }, [getRecords]);
  useEffect(() => {
    const storedRole = sessionStorage.getItem('role');
    storedRole && setUserRole(storedRole);
  }, []);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({ message: '', isOpen: false });
  };

  const exportPDF = async () => {
    const content = document.getElementById('export');

    if (!content) {
      return;
    }

    const pdfContainer = (
      await html2canvas(content, { scale: 0.72 })
    ).toDataURL('image/png');
    const pdf = new jsPDF('p', 'px', 'a4');
    pdf.addImage(pdfContainer, 'PNG', 5, 0, 0, 0);
    pdf.save('download.pdf');

    fetch(`${apiEndpoint}update_specific_printed/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isprinted: true }),
    })
      .then((response) => {
        if (response.status === 200) {
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: 'Record not found' });
        } else {
          setNotification({
            isOpen: true,
            message: 'Failed to update isprinted value',
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: 'Server Error' });
      });
  };
  const handleFinanced = () => {
    fetch(`${apiEndpoint}update_specific_isfinanced/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isfinanced: true }),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsFinanced(true);
          setNotification({ isOpen: true, message: 'Successfully approved' });
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: 'Record not found' });
        } else {
          setNotification({
            isOpen: true,
            message: 'Failed to update finance status',
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: 'Server Error' });
      });
  };
  const handleX = () => {
    fetch(`${apiEndpoint}update_isx_specific/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ isx: true }),
    })
      .then((response) => {
        if (response.status === 200) {
          setxClicked(true);
          setNotification({ isOpen: true, message: 'Successfully approved' });
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: 'Record not found' });
        } else {
          setNotification({
            isOpen: true,
            message: 'Failed to update isprinted value',
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: 'Server Error' });
      });
  };
  const handleCompleted = () => {
    fetch(`${apiEndpoint}update_specific_iscompleted/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ iscompleted: true }),
    })
      .then((response) => {
        if (response.status === 200) {
          setIsFinished(true);

          setNotification({ isOpen: true, message: 'Successfully completed' });
        } else if (response.status === 404) {
          setNotification({ isOpen: true, message: 'Record not found' });
        } else {
          setNotification({
            isOpen: true,
            message: 'Failed to update completed status',
          });
        }
      })
      .catch((error) => {
        setNotification({ isOpen: true, message: 'Server Error' });
      });
  };

  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <Grid
      container
      m="auto"
      my={0.5}
      p={1}
      sx={{ backgroundColor: '#f1f1f1' }}
      direction="column"
      item
      xs={12}
      sm={12}
      md={10}
      lg={8}
    >
      <Breadcrumbs aria-label="breadcrumb">
        <Link color="inherit" to="/user">
          Orders
        </Link>
        <Typography color="text.primary">Order Details</Typography>
        <RefreshIcon sx={{ cursor: 'pointer' }} onClick={handleRefresh} />
      </Breadcrumbs>
      <div id="export" style={{ height: '100%' }}>
        <Stack
          direction="row"
          sx={{ backgroundColor: '#ffffff' }}
          p={1}
          justifyContent="space-between"
        >
          <Typography variant="h6">
            Ref: {userData?.customer.refName}
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          height="100%"
          mt={0.5}
        >
          <Stack
            sx={{ backgroundColor: '#ffffff' }}
            p={1}
            width="45%"
            height="100%"
            fontSize="14px"
          >
            <Typography variant="h6" fontSize="16px">
              Compleet:
            </Typography>
            <Divider flexItem />
            <Box>
              {userData?.order.compleet1.spoor}.Spoor x{' '}
              {userData?.order.compleet1.w} x {userData?.order.compleet1.h}
            </Box>
            {userData?.order.compleet2.w !== '' &&
              userData?.order.compleet2.h !== '' &&
              userData?.order.compleet2.spoor !== '' && (
                <Box>
                  {userData?.order.compleet2.spoor}.Spoor x{' '}
                  {userData?.order.compleet2.w} x {userData?.order.compleet2.h}
                </Box>
              )}
            {userData?.order.compleet3.w !== '' &&
              userData?.order.compleet3.h !== '' &&
              userData?.order.compleet3.spoor !== '' && (
                <Box>
                  {userData?.order.compleet3.spoor}.Spoor x{' '}
                  {userData?.order.compleet3.w}x {userData?.order.compleet3.h}
                </Box>
              )}
            {userData?.order.compleet4.w !== '' &&
              userData?.order.compleet4.h !== '' &&
              userData?.order.compleet4.spoor !== '' && (
                <Box>
                  {userData?.order.compleet4.spoor}.Spoor x{' '}
                  {userData?.order.compleet4.w}x {userData?.order.compleet4.h}
                </Box>
              )}
            {userData?.order.compleet5.w !== '' &&
              userData?.order.compleet5.h !== '' &&
              userData?.order.compleet5.spoor !== '' && (
                <Box>
                  {userData?.order.compleet5.spoor}.Spoor x{' '}
                  {userData?.order.compleet5.w}x {userData?.order.compleet5.h}
                </Box>
              )}
            {userData?.order.compleet6.w !== '' &&
              userData?.order.compleet6.h !== '' &&
              userData?.order.compleet6.spoor !== '' && (
                <Box>
                  {userData?.order.compleet6.spoor}.Spoor x{' '}
                  {userData?.order.compleet6.w}x {userData?.order.compleet6.h}
                </Box>
              )}
            {userData?.order.compleet7.w !== '' &&
              userData?.order.compleet7.h !== '' &&
              userData?.order.compleet7.spoor !== '' && (
                <Box>
                  {userData?.order.compleet7.spoor}.Spoor x{' '}
                  {userData?.order.compleet7.w}x {userData?.order.compleet7.h}
                </Box>
              )}
            {userData?.order.compleet8.w !== '' &&
              userData?.order.compleet8.h !== '' &&
              userData?.order.compleet8.spoor !== '' && (
                <Box>
                  {userData?.order.compleet8.spoor}.Spoor x{' '}
                  {userData?.order.compleet8.w}x {userData?.order.compleet8.h}
                </Box>
              )}
          </Stack>
          <Stack
            sx={{ backgroundColor: '#ffffff' }}
            p={1}
            width="45%"
            height="100%"
            fontSize="14px"
          >
            <Typography variant="h6" fontSize="16px">
              Glasses:
            </Typography>
            <Divider flexItem />
            <Box>
              {userData?.order.cam1.type}: {userData?.order.cam1.amount}x{' '}
              {userData?.order.cam1.w}x {userData?.order.cam1.h}{' '}
              {userData?.order.cam1?.note}
            </Box>
            {userData?.order.cam2.w !== '' && userData?.order.cam2.h !== '' && (
              <Box>
                {userData?.order.cam2.type}: {userData?.order.cam2.amount}x{' '}
                {userData?.order.cam2.w}x {userData?.order.cam2.h}{' '}
                {userData?.order.cam2?.note}
              </Box>
            )}
            {userData?.order.cam3.w !== '' && userData?.order.cam3.h !== '' && (
              <Box>
                {userData?.order.cam3.type}: {userData?.order.cam3.amount}x
                {userData?.order.cam3.w}x {userData?.order.cam3.h}{' '}
                {userData?.order.cam3?.note}
              </Box>
            )}
            {userData?.order.cam4.w !== '' && userData?.order.cam4.h !== '' && (
              <Box>
                {userData?.order.cam4.type}: {userData?.order.cam4.amount}x{' '}
                {userData?.order.cam4.w}x {userData?.order.cam4.h}{' '}
                {userData?.order.cam4?.note}
              </Box>
            )}
            {userData?.order.cam5.w !== '' && userData?.order.cam5.h !== '' && (
              <Box>
                {userData?.order.cam5.type}: {userData?.order.cam5.amount}x{' '}
                {userData?.order.cam5.w}x {userData?.order.cam5.h}{' '}
                {userData?.order.cam5?.note}
              </Box>
            )}
            {userData?.order.cam6.w !== '' && userData?.order.cam6.h !== '' && (
              <Box>
                {userData?.order.cam6.type}: {userData?.order.cam6.amount}x{' '}
                {userData?.order.cam6.w}x {userData?.order.cam6.h}{' '}
                {userData?.order.cam6?.note}
              </Box>
            )}
            {userData?.order.cam7.w !== '' && userData?.order.cam7.h !== '' && (
              <Box>
                {userData?.order.cam7.type}: {userData?.order.cam7.amount}x{' '}
                {userData?.order.cam7.w}x {userData?.order.cam7.h}{' '}
                {userData?.order.cam7?.note}
              </Box>
            )}
            {userData?.order.cam8.w !== '' && userData?.order.cam8.h !== '' && (
              <Box>
                {userData?.order.cam8.type}: {userData?.order.cam8.amount}x{' '}
                {userData?.order.cam8.w}x {userData?.order.cam8.h}{' '}
                {userData?.order.cam8?.note}
              </Box>
            )}
          </Stack>
        </Stack>
        <Stack
          sx={{ backgroundColor: '#ffffff' }}
          p={1}
          my={0.5}
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          fontSize="14px"
        >
          <Stack>
            <Box>{userData?.order.ts}x T.S</Box>
            <Box>{userData?.order.hv}x H.V</Box>
          </Stack>
          <Stack>Color: {userData?.order.colorCode.value}</Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Stack
            sx={{ backgroundColor: '#ffffff' }}
            p={1}
            my={0.5}
            alignItems="flex-start"
            justifyContent="space-between"
            fontSize="14px"
            width="75%"
          >
            <Typography>Note: {userData?.order.note}</Typography>
          </Stack>

          <Stack
            sx={{ backgroundColor: '#ffffff' }}
            p={1}
            my={0.5}
            alignItems="flex-start"
            justifyContent="space-between"
            width="25%"
          >
            <Typography>Price: {userData?.order.price}</Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ backgroundColor: '#ffffff' }}
          my={0.5}
          spacing={30}
        >
          <Typography sx={{ fontSize: !small ? 14 : 16, padding: 1 }}>
            <strong>Client:</strong> {userData?.customer.clientName}
          </Typography>
          <Typography fontWeight={600} color="tomato">
            {userData?.customer.isGripp ? 'GRIPP' : ''}
          </Typography>
        </Stack>
        <Stack
          sx={{ backgroundColor: '#ffffff' }}
          p={1}
          my={0.5}
          alignItems="flex-start"
          justifyContent="space-between"
        >
          <Typography variant="h6" fontSize="16px">
            Address:
          </Typography>
          <Divider flexItem />
          <Typography>
            {userData?.customer.street.toUpperCase()} -
            {userData?.customer.houseNumber}- {userData?.customer.postcode}-{' '}
            {userData?.customer.city}
          </Typography>
          <Typography>{userData?.customer.city.toUpperCase()}</Typography>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Stack
            sx={{ backgroundColor: '#ffffff' }}
            p={1}
            alignItems="flex-start"
            justifyContent="space-between"
            width="50%"
            fontSize="14px"
          >
            <Typography variant="h6" fontSize="16px">
              Client note:{' '}
            </Typography>
            <span>{userData?.customer.userNote}</span>
          </Stack>
          <Stack
            sx={{ backgroundColor: '#ffffff' }}
            p={1}
            width="50%"
            fontSize="14px"
          >
            <Typography width="100%">
              Email: {userData?.customer.email}
            </Typography>
            <Typography width="100%">
              Phone Number: {userData?.customer.phoneNumber}
            </Typography>
          </Stack>
        </Stack>
      </div>

      <Stack
        direction="row"
        alignItems="center"
        spacing={1}
        justifyContent="flex-end"
        mt={0}
      >
        {(userRole === 'admin' || userRole === 'mod') && (
          <Button
            variant="contained"
            color="success"
            onClick={() => navigate(`/admin/editspecificform/${id}`)}
            disabled={userData?.order.isx || xClicked}
          >
            Edit
          </Button>
        )}
        {userRole === 'admin' && (
          <Button
            variant="contained"
            color="success"
            onClick={handleX}
            disabled={userData?.order.isx || xClicked}
          >
            X Control
          </Button>
        )}
        {(userRole === 'administratie' || userRole === 'admin') && (
          <Button variant="outlined" onClick={exportPDF}>
            Export as PDF
          </Button>
        )}
        {(userRole === 'administratie' || userRole === 'admin') && (
          <Button
            variant="contained"
            onClick={handleFinanced}
            color="success"
            disabled={userData?.order.isfinanced || isFinanced}
          >
            {userData?.order.isfinanced ? 'F Approved' : 'F Approve'}
          </Button>
        )}
        {(userRole === 'user' || userRole === 'admin') && (
          <Button
            variant="contained"
            onClick={handleCompleted}
            color="success"
            disabled={userData?.order.iscompleted || isFinished}
          >
            Complete Order
          </Button>
        )}
      </Stack>
      <Snackbar
        open={notification.isOpen}
        autoHideDuration={4000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
          {notification.message}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
