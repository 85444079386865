export const cardStyles = {
  backgroundColor: "#FFFFED",
  cursor: "pointer",
  position: "relative",
  paddingX: 1,
  marginY: 0.5,
  borderRadius: 1,
};

export const textStyles = {
  fontSize: 10,
};

export const refNameStyles = {
  fontSize: 10,
  fontWeight: 600,
  wordBreak: "break-word",
};
