import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Login } from "./pages/Login";
import { Admin } from "./pages/Admin";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CreateForm } from "./pages/CreateForm";
import { ListOrders } from "./pages/ListOrders";
import { User } from "./pages/User";
import { Agenda } from "./pages/Agenda";
import { UserDetail } from "./pages/UserDetail";
import { UserProvider } from "./contexts/UserContext";
import { UsedMaterials } from "./pages/UsedMaterials";
import { CreatePreOrderForm } from "./pages/CreatePreOrderForm";
import { CreateSpecificForm } from "./pages/CreateSpecificForm";
import { Specific } from "./pages/Specific";
import { SpecificDetail } from "./pages/SpecificDetail";
import { EditForm } from "./pages/EditForm";
import { DateNotes } from "./pages/DateNotes";

function App() {
  return (
    <Router>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <UserProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="admin" element={<Admin />}>
              <Route path="create" element={<CreateForm />} />
              <Route path="approvedpre" element={<CreateForm />}>
                <Route path=":id" element={<CreateForm />} />
              </Route>
              <Route path="editnormalform" element={<EditForm />}>
                <Route path=":id" element={<EditForm />} />
              </Route>
              <Route path="editspecificform" element={<EditForm />}>
                <Route path=":id" element={<EditForm />} />
              </Route>
              <Route path="datenotes" element={<DateNotes/>}></Route>
              <Route path="createpre" element={<CreatePreOrderForm />} />
              <Route path="createSpecific" element={<CreateSpecificForm />} />
              <Route path="usedmaterials" element={<UsedMaterials />} />
              <Route index element={<ListOrders />} />
            </Route>
            <Route path="user" element={<User />}>
              <Route path=":id" element={<UserDetail />} />
              <Route index element={<Agenda />} />
            </Route>
            <Route path="specific" element={<Specific />}>
              <Route path=":id" element={<SpecificDetail />} />
              <Route index element={<Agenda />} />
            </Route>
          </Routes>
        </UserProvider>
      </LocalizationProvider>
    </Router>
  );
}

export default App;
