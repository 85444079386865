import {  Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import { SimpleData } from "../../../api/dummyList";
import { refNameStyles, textStyles } from "./styles";

interface AgendaCardNormalProps {
  data: SimpleData;
  url: string;
  totalGlasses: number;
  bgColor: string;
}

const AgendaCardNormal: React.FC<AgendaCardNormalProps> = ({
  data,
  url,
  totalGlasses,
  bgColor,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      key={data.id}
      sx={{
        backgroundColor: bgColor,
        cursor: "pointer",
        position: "relative",
        paddingX: 1.5,
        marginY: 0.5,
        borderRadius: 1,
      }}
      onClick={() => navigate(url)}
    >
      <Typography
        sx={{
          position: "absolute",
          right: "2px",
          top: "-2px",
          color: "green",
          fontSize: 10,
          fontWeight: 800,
        }}
      >
        {data.order.isx && "X"}
        {data.order.isprinted && data.order.isx && "-"}
        {!data.order.isprinted &&
          data.order.isx &&
          data.order.isfinanced &&
          "-"}
        {data.order.isprinted && "P"}
        {data.order.isprinted && data.order.isfinanced && "-"}
        {data.order.isfinanced && "F"}
      </Typography>

      {data.order.iscompleted && (
        <CheckIcon
          sx={{
            position: "absolute",
            right: "2px",
            bottom: "0px",
            color: "green",
            fontSize: 16,
            fontWeight: "bold",
          }}
        />
      )}

      <Typography variant="subtitle2" sx={[textStyles, refNameStyles]}>
        {`${isNaN(totalGlasses) ? 0 : totalGlasses} - ${data.customer.refName}`}
      </Typography>
      <Typography variant="subtitle2" sx={textStyles}>
        {data.period} &nbsp; {data.customer.city}
      </Typography>
    </Stack>
  );
};

export default AgendaCardNormal;
