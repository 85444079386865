import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import { Alert, TextField } from "@mui/material";
import { useState } from "react";
import apiEndpoint from "../api/apiEndpoints";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function MuiDialog(props: any) {
  const { openDialog, setOpenDialog } = props;
  const [date, setDate] = React.useState<Date | null>();
  const [dateNote, setDateNote] = React.useState<string | null>();
  const [showAlert, setShowAlert] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
    handleSave();
  };
  const handleSave = () => {
    const noteToSend = {
      date: date,
      note: dateNote,
    };
    fetch(`${apiEndpoint}datenote`, {
      // fetch("https://backend.yazikafasi.com/api/datenote", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(noteToSend), // Serialize the dataToSend object to JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response from the server if needed
        console.log("Server response:", data);
        setShowAlert(true);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };
  return (
    <>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Add Specific Note to Date
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DatePicker
            sx={{ width: "100%" }}
            label="Form Date"
            value={date}
            onChange={(date: Date | null) => setDate(date)}
            format="DD/MM/YYYY"
          />
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            multiline
            minRows={7}
            label="Date Note"
            variant="outlined"
            value={dateNote}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setDateNote(e.target.value)
            }
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Save note
          </Button>
        </DialogActions>
      </BootstrapDialog>
      {showAlert && (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Note successfully added
        </Alert>
      )}
    </>
  );
}
