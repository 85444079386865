// SecondForm.tsx
import { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Divider,
  MenuItem,
  Select,
  Stack,
  Grid,
  Button,
  Typography,
  useMediaQuery,
} from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { Cam, Compleet, OrderFormData } from "./Stepper";
import { range } from "lodash";

type Props = {
  orderFormData: OrderFormData;
  setOrderFormData: (form: OrderFormData) => void;
};

const GlassColorOptions = [
  { value: "RAL9005 Zwart" },
  { value: "RAL7016 Antraciet" },
  { value: "RAL9001 Creme" },
  { value: "RAL9010 Wit" },
];
const compleetWidthOptions = [
  { value: "82" },
  { value: "90" },
  { value: "96" },
  { value: "103" },
];
const compleetHeightOptions = [
  { value: "190" },
  { value: "195" },
  { value: "200" },
  { value: "205" },
  { value: "210" },
  { value: "215" },
  { value: "220" },
  { value: "225" },
  { value: "230" },
  { value: "235" },
  { value: "240" },
  { value: "245" },
  { value: "250" },
  { value: "255" },
  { value: "260" },
];
const compleetSpoorOptions = [
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
];
const amountOptions = Array.from({ length: 51 }, (_, index) => index);

function OrderForm({ setOrderFormData, orderFormData }: Props) {
  const [rowCount, setRowCount] = useState(3);
  const handleFieldChange = (
    fieldName: keyof OrderFormData,
    value: string | Compleet | Cam | null | undefined | { value: string }
  ) => {
    setOrderFormData({
      ...orderFormData,
      [fieldName]: value,
    });
  };

  const small = useMediaQuery("(min-width:1280px)");
  return (
    <form>
      <Stack
        direction="row"
        justifyContent="space-between"
        flexWrap="wrap"
        my={0.5}
      >
        {/* Compleet Fields */}
        <Stack border="solid 1px #ddd" p={0.5} width={!small ? "100%" : "40%"}>
          <Typography variant="h4" m="auto">
            Compleet
          </Typography>
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            my={1}
            width="100%"
            justifyContent="space-around"
          >
            <Typography>Spoor</Typography>
            <Typography>Width</Typography>
            <Typography>Height</Typography>
          </Stack>

          {range(1, rowCount + 1).map((item:number) => (
            <Grid key={item}>
              <CompleetRow
                orderFormData={orderFormData}
                handleFieldChange={handleFieldChange}
                item={item}
              />
            </Grid>
          ))}

          {rowCount < 8 && (
            <Button
              variant="outlined"
              style={{
                marginTop: 8,
                padding: "8px 16px",
                gap: 5,
              }}
              disabled={rowCount >= 8}
              onClick={() => setRowCount(rowCount + 1)}
            >
              <Typography variant="body2">Add</Typography>
              <AddCircleOutlineOutlinedIcon />
            </Button>
          )}
        </Stack>

        {/* Glass Fields */}
        <Stack border="solid 1px #ddd" p={0.5} width={!small ? "100%" : "54%"}>
          <Typography variant="h4" m="auto">
            Glass
          </Typography>
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            my={1}
            width="100%"
            justifyContent="space-around"
          >
            <Typography>Amount</Typography>
            <Typography>Width</Typography>
            <Typography>Height</Typography>
            <Typography>Note</Typography>
          </Stack>
          {range(1, rowCount + 1).map((item:number) => (
            <Grid key={item}>
              <GlassRow
                orderFormData={orderFormData}
                handleFieldChange={handleFieldChange}
                item={item}
              />
            </Grid>
          ))}
          {rowCount < 8 && (
            <Button
              variant="outlined"
              style={{
                marginTop: 8,
                padding: "8px 16px",
                gap: 5,
              }}
              disabled={rowCount >= 8}
              onClick={() => setRowCount(rowCount + 1)}
            >
              <Typography variant="body2">Add</Typography>
              <AddCircleOutlineOutlinedIcon />
            </Button>
          )}
        </Stack>
      </Stack>

      {/* Similar code for cam2, cam3, cam4 */}

      {/* Other Fields */}
      <Stack direction="row" alignItems="center" spacing={2} my={1}>
        <Autocomplete
          id="controlled-demo"
          fullWidth
          size="small"
          disableClearable
          value={orderFormData.colorCode}
          options={GlassColorOptions}
          getOptionLabel={(option: { value: string }) => option.value}
          onChange={(event: any, newValue: { value: string }) => {
            handleFieldChange("colorCode", newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select ColorCode"
              variant="outlined"
            />
          )}
        />
        <TextField
          label="TS"
          variant="outlined"
          size="small"
          fullWidth
          value={orderFormData.ts}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("ts", e.target.value)
          }
        />
        <TextField
          label="HV"
          variant="outlined"
          size="small"
          fullWidth
          value={orderFormData.hv}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("hv", e.target.value)
          }
        />
        <TextField
          label="Price"
          variant="outlined"
          size="small"
          fullWidth
          value={orderFormData.price}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("price", e.target.value)
          }
        />
      </Stack>
      <TextField
        sx={{ my: 1 }}
        label="Note"
        variant="outlined"
        size="small"
        fullWidth
        multiline
        minRows={1}
        value={orderFormData.note}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange("note", e.target.value)
        }
      />
    </form>
  );
}

export default OrderForm;

type CompleetRowsProps = {
  orderFormData: OrderFormData;
  handleFieldChange: (
    fieldName: keyof OrderFormData,
    value: string | Compleet | Cam | null | undefined | { value: string }
  ) => void;
  item: number;
};

const CompleetRow = (props: CompleetRowsProps) => {
  const { orderFormData, handleFieldChange, item } = props;
  const key = `compleet${item}` as keyof OrderFormData;
  const value = orderFormData[key] as Compleet;

  return (
    <Stack
      direction="row"
      alignItems="center"
      my={1}
      spacing={1}
      sx={{ height: 48 }}
    >
      <Autocomplete
        id="controlled-demo"
        fullWidth
        size="small"
        disableClearable
        value={value.spoor}
        options={compleetSpoorOptions}
        getOptionLabel={(option: { value: string }) => option.value}
        onChange={(event: any, newValue: { value: string }) => {
          handleFieldChange(key, {
            ...value,
            spoor: newValue,
          });
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select Spoor" variant="outlined" />
        )}
      />
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={value?.w || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange(key, {
            ...value,
            w: e.target.value,
          })
        }
      />
      <Typography>X</Typography>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={value?.h || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange(key, {
            ...value,
            h: e.target.value,
          })
        }
      />
    </Stack>
  );
};

const GlassRow = (props: CompleetRowsProps) => {
  const { orderFormData, handleFieldChange, item } = props;
  const key = `cam${item}` as keyof OrderFormData;
  const value = orderFormData[key] as Cam;
  return (
    <Stack
      direction="row"
      alignItems="center"
      my={1}
      spacing={1}
      sx={{ height: 48 }}
    >
      <Select
        labelId={key}
        id={`${key}-select`}
        fullWidth
        value={value?.amount}
        size="small"
        label="Glass Amount"
        onChange={(event: any) =>
          handleFieldChange(key, {
            ...value,
            amount: event.target.value,
          })
        }
      >
        {amountOptions.map((amount, i) => {
          return (
            <MenuItem key={i} value={amount}>
              {amount}
            </MenuItem>
          );
        })}
      </Select>
      <Typography>X</Typography>
      <Autocomplete
        id="controlled-demo"
        fullWidth
        size="small"
        value={value?.w}
        options={compleetWidthOptions}
        getOptionLabel={(option: { value: string }) => option.value}
        onChange={(event: any, newValue: { value: string } | null) => {
          handleFieldChange(key, {
            ...value,
            w: newValue,
          });
        }}
        renderInput={(params) => (
          <TextField {...params} label="Glass Width" variant="outlined" />
        )}
      />
      <Typography>X</Typography>
      <Autocomplete
        id="controlled-demo"
        fullWidth
        value={value?.h}
        size="small"
        options={compleetHeightOptions}
        getOptionLabel={(option: { value: string }) => option.value}
        onChange={(event: any, newValue: { value: string } | null) => {
          handleFieldChange(key, {
            ...value,
            h: newValue,
          });
        }}
        renderInput={(params) => (
          <TextField {...params} label="Glass Height" variant="outlined" />
        )}
      />
      <TextField
        label="Note"
        variant="outlined"
        size="small"
        fullWidth
        value={value?.note}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange(key, {
            ...value,
            note: e.target.value,
          })
        }
      />
    </Stack>
  );
};
