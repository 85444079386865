import { DatePicker } from "@mui/x-date-pickers";
import { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import { Grid, Stack } from "@mui/material";
import { PreFormData } from "../pages/CreatePreOrderForm";
import { Dayjs } from "dayjs";

type Props = {
  formData: PreFormData;
  setFormData: (form: PreFormData) => void;
};

function PreOrderForm({ formData, setFormData }: Props) {
  const handleFieldChange = (
    fieldName: keyof PreFormData,
    value: string | Date | Dayjs | null
  ) => {
    setFormData({
      ...formData,
      [fieldName]: value,
    });
  };

  return (
    <form>
      <Grid container direction="row" spacing={1} mt={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Ref Name"
            variant="outlined"
            value={formData.refName}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange("refName", e.target.value)
            }
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <DatePicker
            sx={{ width: "100%" }}
            label="Form Date"
            value={formData.selectedDate}
            onChange={(date: Date | Dayjs | null) =>
              handleFieldChange("selectedDate", date)
            }
            format="DD/MM/YYYY"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            fullWidth
            label="Period 10:00-12:00"
            variant="outlined"
            value={formData.period}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange("period", e.target.value)
            }
          />
        </Grid>
      </Grid>

      <Stack my={2} border="solid 1px #d9d9d9">
        <Stack p={2}>
          <TextField
            label="Glass Amount"
            variant="outlined"
            fullWidth
            value={formData.amount}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange("amount", e.target.value)
            }
          />
          <Stack direction="row" alignItems="center" spacing={2} my={1}>
            <TextField
              label="City"
              variant="outlined"
              fullWidth
              value={formData.city}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleFieldChange("city", e.target.value)
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </form>
  );
}

export default PreOrderForm;
