import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  Stack,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import apiEndpoint from '../api/apiEndpoints';

function Copyright(props: any) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" mt={4}>
      <Typography variant="body2" color="text.secondary" mr={1}>
        Copyright ©
      </Typography>
      <Typography color="inherit" mr={1}>
        MST
      </Typography>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Stack>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export const Login = () => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const { setUser } = useUser(); // Access setUser from the context
  const navigate = useNavigate();
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const username = data.get('username');
    const password = data.get('password');
    fetch(`${apiEndpoint}login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }), // Serialize the dataToSend object to JSON
    })
      .then((response) => {
        if (!response.ok) {
          setErrorMessage('Username or password wrong...');
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response from the server if needed
        if (
          data.userInfo === 'user' ||
          data.userInfo === 'admin' ||
          data.userInfo === 'administratie' ||
          data.userInfo === 'mod'
        ) {
          sessionStorage.setItem('role', data.userInfo);
          navigate('/user');
          setUser({ role: data.userInfo });
        } else {
          setErrorMessage('Username or password wrong...');
          navigate('/');
        }
      })

      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  };
  useEffect(() => {
    const storedRole = sessionStorage.getItem('role');
    if (storedRole) {
      setUser({ role: storedRole });
    }
  }, [setUser]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setErrorMessage(null);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [errorMessage]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            MST
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <OutlinedInput
              id="password"
              name="password"
              label="Password"
              fullWidth
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {errorMessage && (
              <Typography color="error">{errorMessage}</Typography>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Log in
            </Button>
            {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
};
