import { Alert, Button, IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import PreOrderForm from "../components/PreOrderForm";
import apiEndpoint from "../api/apiEndpoints";
import dayjs, { Dayjs } from "dayjs";

export interface PreFormData {
  id?: number | string;
  refName: string;
  city: string;
  selectedDate: Date | Dayjs | null;
  period: string;
  amount: string;
  isapproved: boolean | null;
}

export const CreatePreOrderForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<PreFormData>({
    refName: "",
    city: "",
    selectedDate: dayjs(),
    period: "",
    amount: "",
    isapproved: false,
  });

  const [showAlert, setShowAlert] = useState(false);
  const dataToSend = {
    formData: formData,
  };

  const handleSave = () => {
    fetch(`${apiEndpoint}createpreorderform`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend), // Serialize the dataToSend object to JSON
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response from the server if needed
        setShowAlert(true);
        setTimeout(() => navigate("/user"), 1200);
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  };

  return (
    <Stack
      width="80%"
      m="auto"
      padding={2}
      sx={{ backgroundColor: "#FFFFFF" }}
      my={3}
    >
      <Stack>
        <PreOrderForm formData={formData} setFormData={setFormData} />

        <Button
          sx={{ ml: "auto" }}
          disabled={formData.refName === "" || formData.selectedDate === null}
          onClick={handleSave}
        >
          Save
        </Button>

        {showAlert && (
          <Alert
            severity="success"
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setShowAlert(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            Successfully created!
          </Alert>
        )}
      </Stack>
    </Stack>
  );
};
