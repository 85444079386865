import React, { useCallback, useEffect, useState } from 'react';
import { Button, Divider, Grid, Stack, Typography } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs, { Dayjs } from 'dayjs';
import { SimpleData } from '../api/dummyList';
import apiEndpoint from '../api/apiEndpoints';
import { PreFormData } from './CreatePreOrderForm';
import { useUser } from '../contexts/UserContext';
import AgendaCardPreOrder from '../components/agenda/agenda-card/AgendaCardPreOrder';
import AgendaCardNormal from '../components/agenda/agenda-card/AgendaCardNormal';

export const Agenda = () => {
  const [currentDate, setCurrentDate] = useState(
    dayjs().startOf('week').add(1, 'day'), // Start from Monday
  );
  const [orders, setOrders] = useState<Array<SimpleData> | []>([]);
  const [preOrders, setPreOrders] = useState<Array<PreFormData> | []>([]);
  const [specificOrders, setSpecificOrders] = useState<Array<any> | []>([]);
  const [dateNotes, setDateNotes] = useState([]);
  const { user } = useUser();

  const getRecords = useCallback(() => {
    fetch(`${apiEndpoint}read`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);
  const getPreOrdersRecords = useCallback(() => {
    fetch(`${apiEndpoint}preorders`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setPreOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  const getSpecificOrdersRecords = useCallback(() => {
    fetch(`${apiEndpoint}specificforms`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setSpecificOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  const getAllDateNotes = useCallback(() => {
    fetch(`${apiEndpoint}datenotes`)
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        setDateNotes(data.date_notes);
      })
      .catch((error) => {
        console.error('There was a problem with the fetch operation:', error);
      });
  }, []);

  const createWeek = (currentDate: Dayjs) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
      week.push(currentDate.add(i, 'day').format('YYYY-MM-DD'));
    }
    return week;
  };

  const [weekList, setWeekList] = useState<Array<string>>(
    createWeek(currentDate),
  );

  const handlePrevClick = () => {
    const newDate = currentDate.subtract(7, 'day');
    setCurrentDate(newDate);
    setWeekList(createWeek(newDate));
  };

  const handleNextClick = () => {
    const newDate = currentDate.add(7, 'day');
    setCurrentDate(newDate);
    setWeekList(createWeek(newDate));
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  useEffect(() => {
    getRecords();
  }, [getRecords]);

  useEffect(() => {
    getPreOrdersRecords();
  }, [getPreOrdersRecords]);

  useEffect(() => {
    getSpecificOrdersRecords();
  }, [getSpecificOrdersRecords]);

  useEffect(() => {
    getAllDateNotes();
  }, [getAllDateNotes]);

  const normalOrdersWithType: SimpleData[] = orders.map((o: SimpleData) => {
    return { ...o, type: 'normal' };
  });
  const specificOrdersWithType: any[] = specificOrders.map((so: any) => {
    return { ...so, type: 'specific' };
  });
  const totalOrders = normalOrdersWithType.concat(specificOrdersWithType);

  return (
    <>
      <Stack direction="row" spacing={1} mt={2} alignItems="center">
        <Button onClick={handlePrevClick}>Previous Week</Button>
        <Button
          onClick={() => {
            setCurrentDate(dayjs().startOf('week').add(1, 'day'));
            setWeekList(createWeek(dayjs().startOf('week').add(1, 'day'))); // Start from Monday
          }}
        >
          Today
        </Button>
        <Button onClick={handleNextClick}>Next Week</Button>
        <RefreshIcon sx={{ cursor: 'pointer' }} onClick={handleRefresh} />
      </Stack>
      {/* PreOrders */}
      {
        <>
          <Typography variant="h6" fontWeight={700}>
            Pre Orders
          </Typography>
          <Divider flexItem />
          <Grid container mt={1}>
            {weekList.map((d, i) => {
              return (
                <Grid
                  item
                  xs={11}
                  sm={1.6}
                  key={i}
                  sx={{
                    border: 'solid 1px #ddd',
                    paddingX: 0.5,
                  }}
                  mx="auto"
                >
                  <Typography
                    variant="body1"
                    fontWeight={600}
                    sx={{ fontSize: 12 }}
                  >
                    {dayjs(d).format('DD MMM ddd')}
                  </Typography>
                  <Divider />
                  {preOrders
                    .filter(
                      (order) =>
                        dayjs(order.selectedDate).format('DD/MM/YYYY') ===
                        dayjs(d).format('DD/MM/YYYY'),
                    )
                    .map((c, i) => {
                      return (
                        <AgendaCardPreOrder
                          key={i}
                          data={c}
                          url={
                            user.role === 'admin' || user.role === 'mod'
                              ? `/admin/approvedpre/${c.id}`
                              : ''
                          }
                        />
                      );
                    })}
                </Grid>
              );
            })}
          </Grid>
        </>
      }
      {/* Normal Orders */}

      <Typography variant="h6" fontWeight={700}>
        Normal Orders
      </Typography>
      <Divider flexItem />
      <Grid container mt={1}>
        {weekList.map((d, i) => {
          const notes = dateNotes.filter(
            (note: any) =>
              dayjs(note.date).format('DD/MM/YYYY') ===
              dayjs(d).format('DD/MM/YYYY'),
          );
          return (
            <Grid
              item
              xs={11}
              sm={1.6}
              key={i}
              sx={{
                border: 'solid 1px #ddd',
                paddingX: 0.5,
              }}
              mx="auto"
            >
              <Typography
                variant="body1"
                fontWeight={600}
                sx={{ fontSize: 12 }}
              >
                {dayjs(d).format('DD MMM ddd')}
              </Typography>
              <Divider />
              {totalOrders
                .filter(
                  (order) =>
                    dayjs(order.selectedDate).format('DD/MM/YYYY') ===
                    dayjs(d).format('DD/MM/YYYY'),
                )
                .sort(
                  (a, b) =>
                    new Date(b.createdAt).getTime() -
                    new Date(a.createdAt).getTime(),
                )
                .map((c, i) => {
                  const glass1 = parseInt(c.order.cam1.amount);
                  const glass2 =
                    c.order.cam2.amount !== ''
                      ? parseInt(c.order.cam2.amount)
                      : 0;
                  const glass3 =
                    c.order.cam3.amount !== ''
                      ? parseInt(c.order.cam3.amount)
                      : 0;
                  const glass4 =
                    c.order.cam4.amount !== ''
                      ? parseInt(c.order.cam4.amount)
                      : 0;
                  const glass5 =
                    c.order.cam5.amount !== ''
                      ? parseInt(c.order.cam5.amount)
                      : 0;
                  const glass6 =
                    c.order.cam6.amount !== ''
                      ? parseInt(c.order.cam6.amount)
                      : 0;
                  const glass7 =
                    c.order.cam7.amount !== ''
                      ? parseInt(c.order.cam7.amount)
                      : 0;
                  const glass8 =
                    c.order.cam8.amount !== ''
                      ? parseInt(c.order.cam8.amount)
                      : 0;
                  const totalGlasses =
                    glass1 +
                    glass2 +
                    glass3 +
                    glass4 +
                    glass5 +
                    glass6 +
                    glass7 +
                    glass8;
                  return (
                    <AgendaCardNormal
                      key={c.id + c.createdAt}
                      data={c}
                      url={
                        c.type === 'normal'
                          ? `/user/${c.id}`
                          : `/specific/${c.id}`
                      }
                      totalGlasses={totalGlasses}
                      bgColor={
                        c.order.iscompleted
                          ? '#90ee90'
                          : c.type === 'normal'
                          ? '#e9f5f8'
                          : '#ddd'
                      }
                    />
                  );
                })}
              {notes?.map((d: any) => (
                <Typography
                  key={d.id}
                  fontSize="10px"
                  sx={{ backgroundColor: '#fffac8', p: 0.3 }}
                >
                  {d.note}
                </Typography>
              ))}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
