import { Stack, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PreFormData } from "../../../pages/CreatePreOrderForm";
import { cardStyles, refNameStyles, textStyles } from "./styles";

interface AgendaCardPreOrderProps {
  data: PreFormData;
  url: string;
}

const AgendaCardPreOrder: React.FC<AgendaCardPreOrderProps> = ({
  data,
  url,
}) => {
  const navigate = useNavigate();

  return (
    <Stack key={data.id} sx={cardStyles} onClick={() => navigate(url)}>
      <Typography
        variant="subtitle2"
        display="inline"
        sx={[textStyles, refNameStyles]}
      >
        {`${data.amount} - ${data.refName}`}
      </Typography>
      <Typography variant="subtitle2" sx={textStyles}>
        {data.period} &nbsp; {data.city}
      </Typography>
    </Stack>
  );
};

export default AgendaCardPreOrder;
