// SecondForm.tsx
import { ChangeEvent, useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Autocomplete,
  Divider,
  MenuItem,
  Select,
  Stack,
  Typography,
  Grid,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Cam, Compleet } from "./Stepper";
import { SpecificCam } from "./SpecificFormStepper";
import { SpecificOrderFormData } from "./SpecificFormStepper";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { range } from "lodash";

type Props = {
  orderFormData: SpecificOrderFormData;
  setOrderFormData: (form: SpecificOrderFormData) => void;
};

const GlassColorOptions = [
  { value: "RAL9005 Zwart" },
  { value: "RAL7016 Antraciet" },
  { value: "RAL9001 Creme" },
  { value: "RAL9010 Wit" },
];
const compleetWidthOptions = [
  { value: "82" },
  { value: "90" },
  { value: "96" },
  { value: "103" },
];
const compleetHeightOptions = [
  { value: "190" },
  { value: "195" },
  { value: "200" },
  { value: "205" },
  { value: "210" },
  { value: "215" },
  { value: "220" },
  { value: "225" },
  { value: "230" },
  { value: "235" },
  { value: "240" },
  { value: "245" },
  { value: "250" },
  { value: "255" },
  { value: "260" },
];
const compleetSpoorOptions = [
  { value: "3" },
  { value: "4" },
  { value: "5" },
  { value: "6" },
  { value: "7" },
];
const amountOptions = Array.from({ length: 51 }, (_, index) => index);
const typeOptions = ["Getint", "Maatwerk", "Normal"];

function SpecificOrderForm({ setOrderFormData, orderFormData }: Props) {
  const [camType, setCamType] = useState<Record<string, unknown>>({
    cam1type: orderFormData.cam1.type,
    cam2type: orderFormData.cam2.type,
    cam3type: orderFormData.cam3.type,
    cam4type: orderFormData.cam4.type,
    cam5type: orderFormData.cam5.type,
    cam6type: orderFormData.cam6.type,
    cam7type: orderFormData.cam7.type,
    cam8type: orderFormData.cam8.type,
  });

  const [rowCount, setRowCount] = useState(3);

  const handleFieldChange = (
    fieldName: keyof SpecificOrderFormData,
    value:
      | string
      | Compleet
      | Cam
      | null
      | undefined
      | { value: string }
      | { type: string | null }
  ) => {
    setOrderFormData({
      ...orderFormData,
      [fieldName]: value,
    });
  };
  const small = useMediaQuery("(min-width:1280px)");
  return (
    <form>
      {/* Compleet Fields */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        my={1}
      >
        <Stack border="solid 1px #ddd" p={1} width={!small ? "100%" : "40%"}>
          <Typography variant="h4" m="auto">
            Compleet
          </Typography>
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            my={1}
            width="100%"
            justifyContent="space-around"
          >
            <Typography>Spoor</Typography>
            <Typography>Width</Typography>
            <Typography>Height</Typography>
          </Stack>
          {range(1, rowCount + 1).map((item:number) => (
            <Grid key={item}>
              <SpecificCompleetRow
                orderFormData={orderFormData}
                handleFieldChange={handleFieldChange}
                item={item}
              />
            </Grid>
          ))}
          {rowCount < 8 && (
            <Button
              variant="outlined"
              style={{
                marginTop: 8,
                padding: "8px 16px",
                gap: 5,
              }}
              disabled={rowCount >= 8}
              onClick={() => setRowCount(rowCount + 1)}
            >
              <Typography variant="body2">Add</Typography>
              <AddCircleOutlineOutlinedIcon />
            </Button>
          )}
        </Stack>

        {/* Glass Fields */}
        <Stack border="solid 1px #ddd" p={2} width={!small ? "100%" : "54%"}>
          <Typography variant="h4" m="auto">
            Glass
          </Typography>
          <Divider />
          <Stack
            direction="row"
            alignItems="center"
            my={1}
            width="100%"
            justifyContent="space-around"
          >
            <Typography>Type</Typography>
            <Typography>Amount</Typography>
            <Typography>Width</Typography>
            <Typography>Height</Typography>
            <Typography>Note</Typography>
          </Stack>
          {range(1, rowCount + 1).map((item:number) => (
            <Grid key={item}>
              <SpecificGlassRow
                orderFormData={orderFormData}
                handleFieldChange={handleFieldChange}
                item={item}
                camType={camType}
                setCamType={setCamType}
              />
            </Grid>
          ))}
          {rowCount < 8 && (
            <Button
              variant="outlined"
              style={{
                marginTop: 8,
                padding: "8px 16px",
                gap: 5,
              }}
              disabled={rowCount >= 8}
              onClick={() => setRowCount(rowCount + 1)}
            >
              <Typography variant="body2">Add</Typography>
              <AddCircleOutlineOutlinedIcon />
            </Button>
          )}
        </Stack>
      </Stack>

      {/* Other Fields */}
      <Stack direction="row" alignItems="center" spacing={2} my={1}>
        <Autocomplete
          id="controlled-demo"
          fullWidth
          size="small"
          disableClearable
          value={orderFormData.colorCode}
          options={GlassColorOptions}
          getOptionLabel={(option: { value: string }) => option.value}
          onChange={(event: any, newValue: { value: string }) => {
            handleFieldChange("colorCode", newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select ColorCode"
              variant="outlined"
            />
          )}
        />
        <TextField
          label="TS"
          variant="outlined"
          fullWidth
          size="small"
          value={orderFormData.ts}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("ts", e.target.value)
          }
        />
        <TextField
          label="HV"
          variant="outlined"
          size="small"
          fullWidth
          value={orderFormData.hv}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("hv", e.target.value)
          }
        />
        <TextField
          label="Price"
          variant="outlined"
          size="small"
          fullWidth
          value={orderFormData.price}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange("price", e.target.value)
          }
        />
      </Stack>
      <TextField
        sx={{ my: 0.5 }}
        label="Note"
        size="small"
        variant="outlined"
        fullWidth
        multiline
        minRows={1}
        value={orderFormData.note}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange("note", e.target.value)
        }
      />
    </form>
  );
}

export default SpecificOrderForm;

type SpecificCompleetRowsProps = {
  orderFormData: SpecificOrderFormData;
  handleFieldChange: (
    fieldName: keyof SpecificOrderFormData,
    value:
      | string
      | Compleet
      | Cam
      | null
      | undefined
      | { value: string }
      | { type: string | null }
  ) => void;
  item: number;
};

const SpecificCompleetRow = (props: SpecificCompleetRowsProps) => {
  const { orderFormData, handleFieldChange, item } = props;
  const key = `compleet${item}` as keyof SpecificOrderFormData;
  const value = orderFormData[key] as Compleet;
  return (
    <Stack
      direction="row"
      alignItems="center"
      my={1}
      spacing={1}
      style={{ height: 40 }}
    >
      <Autocomplete
        id="controlled-demo"
        fullWidth
        size="small"
        disableClearable
        value={value?.spoor}
        options={compleetSpoorOptions}
        getOptionLabel={(option: { value: string }) => option.value}
        onChange={(event: any, newValue: { value: string }) => {
          handleFieldChange(key, {
            ...value,
            spoor: newValue,
          });
        }}
        renderInput={(params) => (
          <TextField {...params} label="Select Spoor" variant="outlined" />
        )}
      />
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={value?.w || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange(key, {
            ...value,
            w: e.target.value,
          })
        }
      />
      <Typography>X</Typography>
      <TextField
        variant="outlined"
        fullWidth
        size="small"
        value={value?.h || ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange(key, {
            ...value,
            h: e.target.value,
          })
        }
      />
    </Stack>
  );
};

type SpecificCamRowsProps = {
  orderFormData: SpecificOrderFormData;
  handleFieldChange: (
    fieldName: keyof SpecificOrderFormData,
    value:
      | string
      | Compleet
      | Cam
      | null
      | undefined
      | { value: string }
      | { type: string | null }
  ) => void;
  item: number;
  camType: Record<string, unknown>;
  setCamType: React.Dispatch<React.SetStateAction<Record<string, unknown>>>;
};

const SpecificGlassRow = (props: SpecificCamRowsProps) => {
  const { orderFormData, handleFieldChange, item, camType, setCamType } = props;
  const key = `cam${item}` as keyof SpecificOrderFormData;
  const value = orderFormData[key] as SpecificCam;
  return (
    <Stack direction="row" alignItems="center" my={1} spacing={1}>
      <Select
        labelId={key}
        id={`${key}-type`}
        fullWidth
        size="small"
        value={value?.type === null ? "Select type" : value?.type}
        label="Glass Type"
        onChange={(event: any) => {
          handleFieldChange(key, {
            ...value,
            type: event.target.value,
          });
          setCamType({ ...camType, [`cam${item}Type`]: event.target.value });
        }}
      >
        {typeOptions.map((amount, i) => {
          return (
            <MenuItem key={i} value={amount}>
              {amount}
            </MenuItem>
          );
        })}
      </Select>
      <Select
        labelId={key}
        id={`${key}-select`}
        fullWidth
        size="small"
        value={value?.amount}
        label="Glass Amount"
        onChange={(event: any) =>
          handleFieldChange(key, {
            ...value,
            amount: event.target.value,
          })
        }
      >
        {amountOptions.map((amount, i) => {
          return (
            <MenuItem key={i} value={amount}>
              {amount}
            </MenuItem>
          );
        })}
      </Select>
      <Typography>X</Typography>
      {camType[`cam${item}Type`] === "Normal" ? (
        <Autocomplete
          id="controlled-demo"
          fullWidth
          size="small"
          value={value?.w}
          options={compleetWidthOptions}
          getOptionLabel={(option: { value: string }) => option.value}
          onChange={(event: any, newValue: { value: string } | null) => {
            handleFieldChange(key, {
              ...value,
              w: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Glass Width" variant="outlined" />
          )}
        />
      ) : (
        <TextField
          label="Width"
          variant="outlined"
          fullWidth
          size="small"
          value={value?.w?.value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange(key, {
              ...value,
              w: { value: e.target.value },
            })
          }
        />
      )}
      <Typography>X</Typography>
      {camType[`cam${item}Type`] === "Normal" ? (
        <Autocomplete
          id="controlled-demo"
          fullWidth
          size="small"
          value={value?.h}
          options={compleetHeightOptions}
          getOptionLabel={(option: { value: string }) => option.value}
          onChange={(event: any, newValue: { value: string } | null) => {
            handleFieldChange(key, {
              ...value,
              h: newValue,
            });
          }}
          renderInput={(params) => (
            <TextField {...params} label="Glass Height" variant="outlined" />
          )}
        />
      ) : (
        <TextField
          label="Height"
          variant="outlined"
          fullWidth
          size="small"
          value={value?.h?.value}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleFieldChange(key, {
              ...value,
              h: { value: e.target.value },
            })
          }
        />
      )}
      <TextField
        label="Note"
        variant="outlined"
        fullWidth
        size="small"
        value={value?.note}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          handleFieldChange(key, {
            ...value,
            note: e.target.value,
          })
        }
      />
    </Stack>
  );
};
