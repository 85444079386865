import {  useCallback, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import apiEndpoint from "../api/apiEndpoints";

type DateNotesType = {
  id: number;
  date: string;
  note: string;
};
export const DateNotes = () => {
  const [dateNotes, setDateNotes] = useState<Array<DateNotesType> | []>([]);

  const [date, setDate] = useState(null);
  const [filteredData, setFilteredData] = useState<Array<DateNotesType> | []>([]); // Function to filter orders based on the selected date

  const [showAll, setShowAll] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const [selectedId, setSelectedId] = useState<number>();

  const data = showAll ? dateNotes : filteredData;

  const getDateNotes = useCallback(() => {
    fetch(`${apiEndpoint}datenotes`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDateNotes(data.date_notes); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);
  useEffect(() => {
    getDateNotes();
  }, [getDateNotes]);

  const handleDelete = (id: number) => {
    fetch(`${apiEndpoint}datenote/${id}`, { method: "DELETE" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Can not delete");
        }
        return response.json();
      })
      .then((data) => {
        setShowAlert(true);
        getDateNotes();
      })
      .catch((error) => {
        console.error("There was a problem with deleting data:", error);
      });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowAlert(false);
    }, 3000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [showAlert]);
  return (
    <Stack width="75%" m="auto" mt={3}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <DatePicker
          label="Form Date"
          value={date} // Use a default date if selectDay is null
          onChange={(date) => {
            setDate(date);

            setFilteredData(
              dateNotes.filter(
                (dateNote) =>
                  dayjs(dateNote.date).format("DD/MM/YYYY") ===
                  dayjs(date).format("DD/MM/YYYY")
              )
            );
            setShowAll(false);
          }}
          format="DD/MM/YYYY" // Update selectDay when a new date is selected
        />

        <Button
          onClick={() => {
            setDate(null);
            setShowAll(true);
          }}
        >
          See All Data
        </Button>
      </Stack>
      <List>
        <Typography variant="h6">Date Notes</Typography>
        <Divider flexItem />
        {data.length > 0 ? (
          data.map((o) => {
            return (
              <List
                key={o.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  padding: 0,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#e9e9e9",
                    transition: "ease all 0.2s", // Change this to your desired hover color
                  },
                }}
              >
                <ListItem>{dayjs(o.date).format("DD/MM/YYYY")}</ListItem>
                <ListItem>{o.note}</ListItem>
                <ListItem>
                  <DeleteForeverIcon
                    sx={{ color: "red" }}
                    onClick={() => {
                      setShowMessage(true);
                      setSelectedId(o.id);
                    }}
                  />
                </ListItem>

                <Dialog
                  fullWidth
                  open={showMessage}
                  onClose={() => setShowMessage(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Do you really want to delete?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShowMessage(false)}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        console.log(selectedId)
                        selectedId && handleDelete(selectedId);
                        setShowMessage(false);
                      }}
                      autoFocus
                    >
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </List>
            );
          })
        ) : (
          <>There is no note today</>
        )}
      </List>

      {showAlert && (
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setShowAlert(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Successfully deleted!
        </Alert>
      )}
    </Stack>
  );
};
