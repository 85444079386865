import { Stack, Box } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import { useUser } from "../contexts/UserContext";
import { useEffect } from "react";

export const User = () => {
  const navigate = useNavigate();
  const { setUser } = useUser();
  useEffect(() => {
    const storedRole = sessionStorage.getItem("role");
    if (storedRole) {
      setUser({ role: storedRole });
    } else {
      navigate("/");
    }
  }, [navigate, setUser]);

  return (
    <Box sx={{ backgroundColor: "#f9f9f9" }} minHeight="100vh">
      <Header />
      <Stack direction="column" alignItems="start" justifyContent="flex-start">
        <Outlet />
      </Stack>
    </Box>
  );
};
