import { Button, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useCallback, useEffect, useState } from "react";
import { SimpleData } from "../api/dummyList";
import apiEndpoint from "../api/apiEndpoints";

const sortResultsByGlass = (results: any, isSpecific: boolean) => {
  return results.slice().sort((a: any, b: any) => {
    if (
      parseInt(a.glass.split("-")[isSpecific ? 2 : 1]) <
      parseInt(b.glass.split("-")[isSpecific ? 2 : 1])
    )
      return -1;
    if (
      parseInt(a.glass.split("-")[isSpecific ? 2 : 1]) >
      parseInt(b.glass.split("-")[isSpecific ? 2 : 1])
    )
      return 1;
    return 0;
  });
};

export const UsedMaterials = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [orders, setOrders] = useState<Array<SimpleData> | []>([]);
  const [specificOrders, setSpecificOrders] = useState<Array<any> | []>([]);

  const [sortedResults, setSortedResults] = useState<Array<any> | null>();
  const [sortedSpecificResults, setSortedSpecificResults] =
    useState<Array<any> | null>();
  const [sorted, setSort] = useState<string>("");
  const getRecords = useCallback(() => {
    fetch(`${apiEndpoint}read`)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);
  useEffect(() => {
    getRecords();
  }, [getRecords]);

  const getSpecificOrders = useCallback(() => {
    fetch(`${apiEndpoint}specificforms`) 
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSpecificOrders(data); // Update the state with the fetched data
      })
      .catch((error) => {
        console.error("There was a problem with the fetch operation:", error);
      });
  }, []);
  useEffect(() => {
    getSpecificOrders();
  }, [getSpecificOrders]);

  const filterDataByDate = useCallback(
    (orders: Array<SimpleData>, isSpecific: boolean) => {
      const filteredData = orders.filter((item) => {
        const selectedDate = new Date(item.selectedDate);
        return (
          startDate &&
          endDate &&
          selectedDate >= new Date(startDate) &&
          selectedDate <= new Date(endDate)
        );
      });
      // Update state with filtered data
      const groupedData = {};

      filteredData.forEach((item) => {
        const { order } = item;
        Object.keys(order).forEach((cam) => {
          if (
            cam.startsWith("cam") &&
            //@ts-ignore
            order[cam].w &&
            //@ts-ignore
            order[cam].h &&
            //@ts-ignore
            order[cam].amount
          ) {
            //@ts-ignore
            const key = isSpecific
              ? //@ts-ignore
                `${order[cam].type}-${order[cam].w}-${order[cam].h}`
              : //@ts-ignore
                `${order[cam].w}-${order[cam].h}`;

            //@ts-ignore
            if (!groupedData[key]) {
              //@ts-ignore
              groupedData[key] = parseInt(order[cam].amount);
            } else {
              //@ts-ignore
              groupedData[key] += parseInt(order[cam].amount);
            }
          }
        });
      });

      const d = [];
      if (groupedData) {
        for (const [key, value] of Object.entries(groupedData)) {
          //Hide normal used materials
          //  if(!key.includes("Normal")){

          //  d.push({glass:key,amount:value})
          //  }
          d.push({ glass: key, amount: value });
        }
      }
      isSpecific
        ? setSortedSpecificResults(sortResultsByGlass(d, true))
        : setSortedResults(sortResultsByGlass(d, false));
    },
    [endDate, startDate]
  );

  return (
    <Stack sx={{ width: "100%" }}>
      <Stack
        mt={4}
        direction="row"
        alignItems="center"
        justifyContent="space-evenly"
        sx={{ width: "100%" }}
      >
        <Stack direction="row">
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(date) => {
              setStartDate(date);
            }}
            format="DD/MM/YYYY"
          />
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(date) => {
              setEndDate(date);
            }}
            format="DD/MM/YYYY"
          />
        </Stack>
        <Button
          variant="outlined"
          onClick={() => {
            setSort("normal");
            setSortedSpecificResults(null);
            filterDataByDate(orders, false);
          }}
        >
          Get Used Materials
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setSort("specific");
            setSortedResults(null);
            filterDataByDate(specificOrders, true);
          }}
        >
          Get Used Specific Materials
        </Button>
      </Stack>
      <Stack
        width="50%"
        m="auto"
        mt={5}
        direction="row"
        sx={{
          border: "solid 1px #ddd",
          backgroundColor: "#F26641",
        }}
        p={1}
        justifyContent="space-around"
      >
        <Typography variant="h6" color="#FFFFFF">
          {" "}
          Glass
        </Typography>
        <Typography variant="h6" color="#FFFFFF">
          {" "}
          Amount{" "}
        </Typography>
      </Stack>
      <Stack>
        {sorted === "normal" &&
          sortedResults?.map((r: any, i: number) => {
            return (
              <Stack
                key={i}
                width="50%"
                m="auto"
                direction="row"
                sx={{
                  border: "solid 1px #ddd",
                  backgroundColor: i % 2 === 0 ? "#ddd" : "",
                }}
                p={1}
                justifyContent="space-around"
              >
                <Typography>{r.glass} </Typography>
                <Typography> {r.amount} </Typography>
              </Stack>
            );
          })}
        {sorted === "specific" &&
          sortedSpecificResults?.map((r: any, i: number) => {
            const glassNameGroup = r.glass.split("-");
            return (
              <Stack
                key={i}
                width="50%"
                m="auto"
                direction="row"
                sx={{
                  border: "solid 1px #ddd",
                  backgroundColor: i % 2 === 0 ? "#ddd" : "",
                }}
                p={1}
                justifyContent="space-around"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  width="25%"
                >
                  <Typography>{glassNameGroup[0]}</Typography>
                  <Typography>
                    {glassNameGroup[1]}x{glassNameGroup[2]}{" "}
                  </Typography>
                </Stack>
                <Typography> {r.amount} </Typography>
              </Stack>
            );
          })}
      </Stack>
    </Stack>
  );
};
